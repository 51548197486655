<template>
  <section
    v-if="list.length > 1"
    class="breadcrumbs-custom"
  >
    <div class="container">
      <ul
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
        class="breadcrumbs-custom__items"
      >
        <template
          v-for="(breadcrumb, index) in list"
          :key="`breadcrumb-${ index }`"
        >
          <li
            itemscope
            itemprop="itemListElement"
            itemtype="https://schema.org/ListItem"
            class="breadcrumbs-custom__item"
          >
            <nuxt-link
              v-if="index < list.length - 1"
              :to="breadcrumb.path || '/'"
              itemprop="item"
              class="hover-decoration"
            >
              <span itemprop="name">{{ breadcrumb.title }}</span>
            </nuxt-link>
            <span
              v-if="index >= list.length - 1"
              itemprop="name"
            >{{ breadcrumb.title }}</span>
            <meta
              itemprop="position"
              :content="String(index + 1)"
            />
          </li>
          <div
            v-if="index < list.length - 1"
            class="breadcrumbs-custom__arrow"
          />
        </template>
      </ul>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  breadcrumbs: {
    type: Array,
    default: () => []
  }
});
const list = computed(() => {
  const items: any = props?.breadcrumbs || [];
  return items;
});
</script>

<style lang="scss">
.breadcrumbs-custom {
  padding: 24px 0;
  box-sizing: border-box;
}

.breadcrumbs-custom__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs-custom__item {
  span {
    color: var(--text-black);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
  }

  a > span {
    color: var(--text-black);
    font-size: 12px;
    font-weight: 500;
    opacity: 0.5;
  }

  &:hover a > span {
    color: #000;
  }
}

.breadcrumbs-custom__arrow {
  width: 18px;
  height: 1px;
  background-color: #000;
  opacity: 0.5;
  margin: 0 24px;
}
</style>
